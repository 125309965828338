import React from "react";
import armoirieImage from "../pages/assets/images/armoirie.jpg";
import dgsahImage from "../pages/assets/images/logo-dgsah.jpg";
import DtTitle from "../pages/handicaps/personne/partials/show/partials/DtTitle";

function LoginLayout({ children }) {
  return (
    <div>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        {/* auth-page content */}
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <div className="container ">
            <div className="row">
              <div className="col-lg-12 shadow-lg">
                <div className="card overflow-hidden">
                  <div className="row g-0 loginContent parent">
                    <div className="col-lg-6 effect1">
                      <div className="p-lg-5 p-4 auth-one-bg h-100">
                        <div className="bg-overlay" />
                        <div className="position-relative h-100 d-flex flex-column">
                          <div className="mb-4">
                            <a href="index.html" className="d-block">
                              <img
                                src="assets/images/logo-light.png"
                                alt=""
                                height={18}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end col */}
                    <div className="col-lg-6 effect2">
                      <div className="p-lg-5 p-4">
                        <div className="row space-between mb-3">
                          <div className="col-md-6 lg-title">
                            Ministère de la Solidarité, de l'Action humanitaire,
                            de la Réconciliation nationale, du Genre et de la
                            Famille.
                          </div>
                          <div className="col-md-6">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={armoirieImage}
                                alt="Armoirie du Burkina Faso"
                                style={{
                                  margin: "0 10px",
                                  width: "60px",
                                  height: "60px",
                                  borderRadius: "50%",
                                }}
                              />
                              <img
                                src={dgsahImage}
                                alt="Armoirie du Burkina Faso"
                                style={{
                                  margin: "0 10px",
                                  width: "60px",
                                  height: "60px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt-sm-1 text-white-50">
                          <DtTitle
                            title={"Régistre des personnes handicapées"}
                          />
                          <div>
                            <h2 className="text-black text-muted">DGSAH</h2>
                          </div>

                          <p className="mt-1 fs-15 fw-medium text-black text-muted">
                            Direction Générale de la Solidarité et de l'Action
                            Humanitaire
                          </p>
                        </div>
                        <div className="loginContent">{children}</div>
                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            <a
                              href="auth-signup-cover.html"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              {" "}
                            </a>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* end col */}
                  </div>
                  {/* end row */}
                </div>
                {/* end card */}
              </div>
              {/* end col */}
            </div>
            {/* end row */}
          </div>
          {/* end container */}
        </div>
      </div>
    </div>
  );
}

export default LoginLayout;
