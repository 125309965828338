import React from "react";
import NavSearch from "./elements/navbar/NavSearch";
import Logo from "./elements/navbar/Logo";
import RigthItems from "./elements/navbar/RigthItems";

const Navbar = () => {
  return (
    <div>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              {/* LOGO */}
              <Logo />
              {/* App Search*/}
              {/* <NavSearch /> */}
            </div>

            {/* Right Items */}
            <div className="">
              <RigthItems />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
