import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/authContext";

const RoleAndStatusMiddleware = ({
  children,
  allowedRoles,
  statusRequired,
}) => {
  const { auth } = useAuth();

  if (auth.status !== statusRequired) {
    return <Navigate to="/disabled" />;
  } else {
    if (!allowedRoles.includes(auth.role)) {
      return <Navigate to="/unauthorized" />;
    }
  }

  return children;
};

export default RoleAndStatusMiddleware;
