import React, { createContext, useContext, useEffect, useState } from "react";
import authStore from "../../store/auth/auth";
import { Navigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(authStore.getState().user);
  const login = authStore((state) => state.login);
  const logout = authStore((state) => state.logout);
  const register = authStore((state) => state.register);
  const isAuthenticated = !!user;
  const roleLabels = {
    1: "Gestionnaire DGSAH",
    2: "Agent DGSAH",
    3: "DSI",
    4: `Gestionnaire`,
    5: "Agent Terrain",
  };

  const userRole =
    user && user.user.role.value ? roleLabels[user.user.role.value] : "Invité";

  const auth = user
    ? {
        token: user.token,
        id: user.user.id,
        name: user.user.name,
        email: user.user.email,
        phone: user.user.phone,
        profile: user.user.profile,
        status: user.user.status,
        role: user.user.role?.value,
        region_id: user.user.region_id,
        direction: user.user.direction,
        direction_id: user.user.direction_id,
        fonction: user.user.fonction,
        roleName: userRole,
        created_at: user.user.created_at,
      }
    : null;

  useEffect(() => {
    const unsubscribe = authStore.subscribe((state) => {
      setUser(state.user);
    });

    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(user);
    }

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        login,
        logout,
        register,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte d'authentification
export const useAuth = () => useContext(AuthContext);
