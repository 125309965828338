import React from "react";
import { toggleSidebar } from "../../custom-app/app";
import { Link } from "react-router-dom";

function TopLogo() {
  return (
    <div>
      <div className="navbar-brand-box">
        {/* Dark Logo*/}
        <Link to={"/dashboard"} className="logo logo-dark">
          <span className="logo-sm">
            <img src="/armoirie.jpg" alt="" height={30} className="text-center"/>
          </span>
          <span className="logo-lg">
            <img src="/armoirie.jpg" alt="" height={100} className="text-center ml-3"/>
          </span>
        </Link>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
          onClick={toggleSidebar}
        >
          <i className="ri-record-circle-line" />
        </button>
      </div>
    </div>
  );
}

export default TopLogo;
