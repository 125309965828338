import React from "react";

function Logo() {
  return (
    <>
      <div className="navbar-brand-box horizontal-logo">
        <a href="index.html" className="logo logo-dark">
          <span className="logo-sm">
            <img src="adminKit/assets/images/logo-sm.png" alt="" height={22} />
          </span>
          <span className="logo-lg">
            <img
              src="adminKit/assets/images/logo-dark.png"
              alt=""
              height={17}
            />
          </span>
        </a>
        <a href="index.html" className="logo logo-light">
          <span className="logo-sm">
            <img src="adminKit/assets/images/logo-sm.png" alt="" height={22} />
          </span>
          <span className="logo-lg">
            <img
              src="adminKit/assets/images/logo-light.png"
              alt=""
              height={17}
            />
          </span>
        </a>
      </div>
      {/* <button
        type="button"
        className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn"
        id="topnav-hamburger-icon"
      >
        <span className="hamburger-icon open">
          <span />
          <span />
          <span />
        </span>
      </button> */}
    </>
  );
}

export default Logo;
