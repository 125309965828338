import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../api/context/authContext";
import EmptyAvatar from "../../../../pages/users/partials/EmptyAvatar";
import useAlert from "../../../../helpers/ShowAlert";
import { imgUrl } from "../../../../helpers/constData";
import { infoToast } from "../../../../helpers/toast";

function NavProfile() {
  const { auth, logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const { showConfirmation } = useAlert();

  const handleLogout = () => {
    showConfirmation({
      title: "Déconnection !",
      text: "Etes vous sur de vouloir vous déconnecter?",
      icon: "warning",
      confirmButtonText: "Oui, déconnectez-moi",
      cancelButtonText: "Non, annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
        infoToast("Vous etes déconnecté");
        navigate("/");
      }
    });
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>
      <div className="dropdown ms-sm-3 header-item topbar-user">
        <button
          type="button"
          className="btn"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="d-flex align-items-center">
            {auth.profile ? (
              <img
                className="rounded-circle header-profile-user"
                src={`${imgUrl}${auth.profile}`}
              />
            ) : (
              <EmptyAvatar />
            )}
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {auth.name}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {auth.roleName}
              </span>
            </span>
          </span>
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          {/* item*/}
          {/* <h6 className="dropdown-header">Bienvenu {auth.name}!</h6> */}

          <Link to={"/profil"} className="dropdown-item">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />
            <span className="align-middle">Profil</span>
          </Link>
          <div className="dropdown-divider" />
          {/* Utilisation de handleLogout au lieu d'un lien href */}
          <button className="dropdown-item" onClick={handleLogout}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />
            <span className="align-middle">Deconnection</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default NavProfile;
