import React from "react";

function EmptyAvatar() {
  return (
    <div>
      <svg
      className="img-fluid"
        fill="#000000"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="30px"
        height="30px"
        viewBox="796 796 200 200"
        enableBackground="new 796 796 200 200"
        xmlSpace="preserve"
      >
        <path
          d="M993.832,961.778c-6.738-23.498-25.328-45.365-51.463-60.807c6.761-10.893,10.748-24.252,10.748-38.673
	C953.117,825.741,927.495,796,896,796c-31.492,0-57.112,29.741-57.112,66.299c0,14.42,3.986,27.779,10.746,38.671
	c-26.137,15.441-44.728,37.31-51.464,60.806c-2.308,8.038-0.675,16.839,4.375,23.552C807.593,992.009,815.6,996,823.963,996h144.073
	c8.364,0,16.371-3.991,21.427-10.685C994.506,978.614,996.138,969.813,993.832,961.778z M896,808.766
	c24.455,0,44.352,24.015,44.352,53.533c0,29.521-19.896,53.539-44.352,53.539c-24.453,0-44.347-24.018-44.347-53.539
	C851.653,832.78,871.547,808.766,896,808.766z M979.27,977.631c-2.65,3.509-6.85,5.604-11.233,5.604H823.963
	c-4.384,0-8.583-2.095-11.227-5.592c-2.647-3.521-3.506-8.134-2.297-12.346c5.575-19.443,21.603-38.444,43.578-52.051
	c1.94-1.201,4.448-0.952,6.111,0.61c9.812,9.219,22.295,14.747,35.872,14.747s26.063-5.528,35.874-14.746
	c1.665-1.564,4.167-1.813,6.11-0.609c21.975,13.605,38.001,32.606,43.576,52.051C982.77,969.509,981.91,974.123,979.27,977.631z"
        />
      </svg>
    </div>
  );
}

export default EmptyAvatar;
