import React from "react";

const Footer = () => {
  return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              ©
              DGSAH
            </div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Développée par Switch Maker
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;
