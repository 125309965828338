import axios from "axios";
import authStore from "../../store/auth/auth";
import { Navigate } from "react-router-dom";

var server = 11;
var host = "";
if (server === 1) {
  host = "http://localhost:8000/api";
} else {
  host = "https://api.handicapbf.com/api";
}

const axiosClient = axios.create({
  baseURL: host,
  headers: {
    Accept: "application/json",
  },
  timeout: 30000,
  withCredentials: true,
});

axiosClient.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      authStore.getState().logout();
    }
    if (error.response && error.response.status === 429) {
      Navigate("/unauthorized");
    }
    if (error.response && error.response.status === 500) {
      Navigate("/unauthorized");
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
