import { create } from "zustand";
import AuthService from "../../api/services/auth/AuthService";
import translateError from "../../api/utils/translation";

const authStore = create((set) => ({
  user: JSON.parse(localStorage.getItem("user")) || null,
  isAuthenticated: !!localStorage.getItem("user"),
  error: null,
  resetError: () => set({ error: null }),
  isLoading: false,

  login: async (email, password) => {
    try {
      set({ isLoading: true });
      const response = await AuthService.login(email, password);
      localStorage.setItem("user", JSON.stringify(response.data));
      set({ user: response.data, error: null, isAuthenticated: true });
      return response.data.user;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Une erreur est survenue lors de la connexion.";
      set({
        user: null,
        error: errorMessage,
        isAuthenticated: false,
      });
    } finally {
      set({ isLoading: false });
    }
  },

  register: async (name, email, phone, password, profile, role) => {
    try {
      set({ isLoading: true });
      await AuthService.register(name, email, phone, password, profile, role);
    } catch (error) {
      set({ error: translateError(error.message) });
    } finally {
      set({ isLoading: false });
    }
  },

  forgotPassword: async (email) => {
    try {
      set({ isLoading: true });
      await AuthService.forgotPassword(email);
    } catch (error) {
      set({ error: error.message });
    } finally {
      set({ isLoading: false });
    }
  },
  resetPassword: async (reset_code, new_password) => {
    try {
      set({ isLoading: true });
      const response = await AuthService.resetPassword(
        reset_code,
        new_password
      );
      return response.user;
    } catch (error) {
      set({ error: error.message });
    } finally {
      set({ isLoading: false });
    }
  },

  logout: () => {
    localStorage.removeItem("user");
    set({ user: null, isAuthenticated: false });
  },

  // setting errors
  setError: (error) => set({ error }),
}));

export default authStore;
