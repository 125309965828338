import axiosClient from "../../utils/axios";

const login = async (email, password) => {
  try {
    const response = await axiosClient.post("/login", { email, password });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.message ||
          "Une erreur est survenue lors de la connexion."
      );
    } else {
      throw new Error(
        "Le serveur n'a pas pu traiter la demande. Veuillez réessayer plus tard."
      );
    }
  }
};

const register = async (
  name,
  email,
  phone,
  password,
  profile,
  role,
  region_id
) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("password", password);

  if (profile) {
    formData.append("profile", profile);
  }
  if (role) {
    formData.append("role", role);
  }
  if (region_id) {
    formData.append("region_id", region_id);
  }

  try {
    const response = await axiosClient.post("/register", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message ||
        "Une erreur est survenue lors de l'inscription."
    );
  }
};

const forgotPassword = async (email) => {
  try {
    const response = await axiosClient.post("/forgot-password", { email });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.message || "Une erreur est survenue."
      );
    } else {
      throw new Error(
        "Le serveur n'a pas pu traiter la demande. Veuillez réessayer plus tard."
      );
    }
  }
};
const resetPassword = async (reset_code, new_password) => {
  try {
    const response = await axiosClient.post("/change-password", {
      reset_code,
      new_password,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.message || "Une erreur est survenue."
      );
    } else {
      throw new Error(
        "Le serveur n'a pas pu traiter la demande. Veuillez réessayer plus tard."
      );
    }
  }
};

export default {
  login,
  register,
  forgotPassword,
  resetPassword,
};
