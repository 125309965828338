import React from "react";

function GeneralLayout({ children }) {
  return (
    <div id="layout-wrapper">
      {/* MAIN ITEMS */}
      <div className="">
        <div className="page-content">
          <div className="container-fluid">{children}</div>
        </div>
      </div>

      <>
        {/* footer */}
        <footer className="footer start-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0 text-muted">
                    © 2024{" "} | DGSAH
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* end Footer */}
      </>
    </div>
  );
}

export default GeneralLayout;
