import { BrowserRouter } from "react-router-dom";
import RenderRoutes from "./router/router";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Suspense>
          <RenderRoutes />
        </Suspense>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
