import React, { Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Main from "../layouts/AdminLayout";
import GeneralLayout from "../layouts/GeneralLayout";
import LoginLayout from "../layouts/LoginLayout";
import AuthMiddleware from "../api/middlewares/AuthMiddleware";
import RoleAndStatusMiddleware from "../api/middlewares/RoleAndStatusMiddleware";
import { AnimatePresence, motion } from "framer-motion";
import LoadingComponent from "../layouts/loading/LoadingComponent";

function Render({
  component: Component,
  layout: Layout,
  requiresAuth,
  allowedRoles,
  statusRequired,
  ...rest
}) {
  let RenderComponent = (
    <Layout>
      <Suspense fallback={<LoadingComponent />}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, x: "-100%" }}
          transition={{ duration: 0.9, ease: "easeInOut" }}
          // whileTap={{ scale: 0.8 }}
        >
          <Component {...rest} />
        </motion.div>
      </Suspense>
    </Layout>
  );

  // Middleware Role & Status
  if (requiresAuth && allowedRoles && statusRequired) {
    if (requiresAuth) {
      RenderComponent = (
        <RoleAndStatusMiddleware
          allowedRoles={allowedRoles}
          statusRequired={statusRequired}
        >
          {RenderComponent}
        </RoleAndStatusMiddleware>
      );
    }
  }

  // Midleware auth
  if (requiresAuth) {
    RenderComponent = <AuthMiddleware>{RenderComponent}</AuthMiddleware>;
  }

  return RenderComponent;
}

const routes = [
  {
    id: 1,
    href: "/",
    component: lazy(() => import("../pages/auth/Login")),
    layout: LoginLayout,
  },
  {
    id: 2,
    href: "/register",
    component: lazy(() => import("../pages/auth/Register")),
    layout: GeneralLayout,
  },
  {
    id: 3,
    href: "/forgot-password",
    component: lazy(() => import("../pages/auth/ForgotPassword")),
    layout: LoginLayout,
  },
  {
    id: 4,
    href: "/reset-password",
    component: lazy(() => import("../pages/auth/ResetPassword")),
    layout: LoginLayout,
  },
  {
    id: 5,
    href: "/profil",
    component: lazy(() => import("../pages/auth/Profile")),
    layout: Main,
    statusRequired: 1,
    allowedRoles: [1, 2, 3, 4],
  },
  {
    id: 6,
    href: "/nouvel-utilisateur",
    component: lazy(() => import("../pages/users/NewUser")),
    layout: Main,
    requiresAuth: true,
    statusRequired: 1,
    allowedRoles: [1, 3, 4],
  },

  {
    id: 7,
    href: "/dashboard",
    component: lazy(() => import("../pages/dashboard/Dashboard")),
    layout: Main,
    requiresAuth: true,
    statusRequired: 1,
    allowedRoles: [1, 2, 3, 4],
  },
  {
    id: 8,
    href: "/users",
    component: lazy(() => import("../pages/users/Users")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 3, 4],
    statusRequired: 1,
  },
  {
    id: 9,
    href: "/user/:id",
    component: lazy(() => import("../pages/users/ShowUser")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 3, 4],
    statusRequired: 1,
  },
  {
    id: 10,
    href: "/auth/:id",
    component: lazy(() => import("../pages/auth/EditAuth")),
    layout: Main,
    requiresAuth: true,
    statusRequired: 1,
  },

  {
    id: 11,
    href: "/phs",
    component: lazy(() => import("../pages/handicaps/personne/Phs")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2, 3, 4],
    statusRequired: 1,
  },
  {
    id: 11,
    href: "/types-handicaps",
    component: lazy(() => import("../pages/handicaps/type/TypeHandicaps")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2, 3],
    statusRequired: 1,
  },
  {
    id: 11,
    href: "/type/:id",
    component: lazy(() => import("../pages/handicaps/type/TypeHandicaps")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2, 3],
    statusRequired: 1,
  },
  {
    id: 12,
    href: "/all-phs",
    component: lazy(() => import("../pages/handicaps/personne/AllPhs")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2, 3, 4],
    statusRequired: 1,
  },
  {
    id: 122,
    href: "/old-phs",
    component: lazy(() => import("../pages/handicaps/personne/OldPhs")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2, 3, 4],
    statusRequired: 1,
  },
  {
    id: 13,
    href: "/en-attente-validation",
    component: lazy(() =>
      import("../pages/handicaps/personne/AttenteValidation")
    ),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [4],
    statusRequired: 1,
  },
  {
    id: 14,
    href: "/en-attente-complements",
    component: lazy(() =>
      import("../pages/handicaps/personne/AttenteComplements")
    ),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [4],
    statusRequired: 1,
  },
  {
    id: 141,
    href: "/complements-pending",
    component: lazy(() =>
      import("../pages/handicaps/personne/PendingComplements")
    ),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2],
    statusRequired: 1,
  },
  {
    id: 15,
    href: "/ph/:id",
    component: lazy(() => import("../pages/handicaps/personne/ShowPh")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2, 3, 4],
    statusRequired: 1,
  },
  {
    id: 16,
    href: "/directions",
    component: lazy(() => import("../pages/directions/Directions")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 3],
    statusRequired: 1,
  },
  {
    id: 17,
    href: "/nouvelle-direction",
    component: lazy(() => import("../pages/directions/NewDirection")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1],
    statusRequired: 1,
  },
  {
    id: 18,
    href: "/direction/:id",
    component: lazy(() => import("../pages/directions/ShowDirection")),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2, 3, 4],
    statusRequired: 1,
  },
  {
    id: 19,
    href: "/complements/:id",
    component: lazy(() =>
      import("../pages/handicaps/complements/NewComplement")
    ),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2, 4],
    statusRequired: 1,
  },
  {
    id: 20,
    href: "/recherche-avancee",
    component: lazy(() =>
      import("../pages/handicaps/personne/recherche-avancee/RechercheAvancee")
    ),
    layout: Main,
    requiresAuth: true,
    allowedRoles: [1, 2, 3, 4],
    statusRequired: 1,
  },

  {
    id: 403,
    href: "/unauthorized",
    component: lazy(() => import("../pages/redirections/Unautorized")),
    layout: GeneralLayout,
  },

  {
    id: 405,
    href: "/disabled",
    component: lazy(() => import("../pages/redirections/Disabled")),
    layout: GeneralLayout,
  },
  {
    id: 404,
    href: "*",
    component: lazy(() => import("../pages/redirections/NotFound")),
    layout: GeneralLayout,
  },
];

export default function RenderRoutes() {
  const location = useLocation();
  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          {routes.map((route) => (
            <Route
              key={route.id}
              path={route.href}
              element={
                <Render
                  component={route.component}
                  layout={route.layout}
                  requiresAuth={route.requiresAuth}
                  allowedRoles={route.allowedRoles}
                  statusRequired={route.statusRequired}
                />
              }
            />
          ))}
        </Routes>
      </AnimatePresence>
    </>
  );
}
