import React from "react";
// import LanguageSelector from "./right-items/LanguageSelector";
// import WebAppView from "./right-items/WebAppView";
import ThemeChanger from "./right-items/ThemeChanger";
// import NavNotification from "./right-items/NavNotification";
import NavProfile from "./right-items/NavProfile";

function RigthItems() {
  return (
    <div className="d-flex align-items-center">
      <div className="dropdown d-md-none topbar-head-dropdown header-item">
        <button
          type="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          id="page-header-search-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="bx bx-search fs-22" />
        </button>
        <div
          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-search-dropdown"
        >
          <form className="p-3">
            <div className="form-group m-0">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <button className="btn btn-primary" type="submit">
                  <i className="mdi mdi-magnify" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <LanguageSelector /> */}
      {/* <WebAppView /> */}
      {/* <ThemeChanger /> */}
      {/* <NavNotification /> */}
      <NavProfile />
    </div>
  );
}

export default RigthItems;
