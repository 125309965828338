import React from "react";
import LinksTab from "./sidebar-links/LinksTab";

function SidebarLinks() {
  return (
    <div>
      <div id="scrollbar" data-simplebar="init" className="h-100">
        {/* NAV ITEMS */}
        <LinksTab />
        {/* END NAV ITEMS */}

        {/* VISIBILITY */}
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: "visible" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{
              width: 61,
              transform: "translate3d(0px, 0px, 0px)",
              display: "block",
            }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: "visible" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{
              height: 122,
              transform: "translate3d(0px, 0px, 0px)",
              display: "block",
            }}
          />
        </div>
        {/* END VISIBILITY */}
      </div>
    </div>
  );
}

export default SidebarLinks;
