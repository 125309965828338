import React from "react";

function DtTitle({ title }) {
  return (
    <div>
      <div class="text-center">
        <div class="signin-other-title">
          <h5 class="fs-13 mb-1 title" title={title}>
            {title}
          </h5>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default DtTitle;
