import React from "react";
import TopLogo from "./sidebar-items/TopLogo";
import SidebarLinks from "./sidebar-items/SidebarLinks";

const Sidebar = () => {
  return (
    <div>
      <div className="app-menu navbar-menu">
        {/* LOGO */}
        <TopLogo />
        {/* NAV LINKS */}
        <SidebarLinks />
        <div className="sidebar-background" />
      </div>

      {/* vertical */}
      <div className="vertical-overlay" />
    </div>
  );
};

export default Sidebar;
