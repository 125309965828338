import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../api/context/authContext";
import { getDirectionDisplayText } from "../../../pages/users/partials/mixin";

function LinksTab() {
  const { auth } = useAuth();
  const role = auth.role;

  return (
    <div>
      <div className="simplebar-wrapper" style={{ margin: 0 }}>
        <div className="simplebar-content" style={{ padding: 0 }}>
          <div className="container-fluid">
            <ul className="navbar-nav" id="navbar-nav">
              <li className="menu-title">
                {[4].includes(role) ? (
                  <span>{getDirectionDisplayText(auth.direction)}</span>
                ) : (
                  <>
                    {role === 3 && <span className="text-green">DSI</span>}
                    {[1, 2].includes(role) && (
                      <span className="text-green">DGSAH</span>
                    )}
                  </>
                )}
              </li>

              <li className="nav-item">
                <Link to={"/dashboard"} className="nav-link menu-link">
                  <i className="las la-tachometer-alt"></i>
                  <span>Tableau de bord</span>
                </Link>
              </li>
              <hr />
              <li className="menu-title">
                <span>Personnes handicapées</span>
              </li>
              <li className="nav-item">
                <Link to={"/recherche-avancee"} className="nav-link menu-link">
                  <i className="las la-search"></i>{" "}
                  <span>Recherche avancée</span>
                </Link>
              </li>
              {/** Dropdown for En attente de soumission et En attente de compléments **/}
              {[4].includes(role) && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link menu-link dropdown-toggle"
                    href="#"
                    id="pendingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="las la-clock"></i>
                    <span>Attentes</span>
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="pendingDropdown"
                  >
                    <li>
                      <Link
                        to={"/en-attente-validation"}
                        className="dropdown-item"
                      >
                        <i className="lar la-clock"></i>
                        <span>En attente de validation</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/en-attente-complements"}
                        className="dropdown-item"
                      >
                        <i className="las la-hourglass-half"></i>
                        <span>En attente de compléments</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              <li className="nav-item">
                <Link to={"/phs"} className="nav-link menu-link">
                  <i className="las la-check-square"></i>{" "}
                  <span>Enrôlements validés</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/all-phs"} className="nav-link menu-link">
                  <i className="las la-user-injured"></i>{" "}
                  <span>Tous les enrôlements</span>
                </Link>
              </li>
              {[1, 2].includes(role) && (
                <>
                  <hr />
                  <li>
                    <Link
                      to={"/complements-pending"}
                      className="nav-link menu-link"
                    >
                      <i className="las la-hourglass-half"></i>
                      <span>Compléments en attente de validation</span>
                    </Link>
                  </li>
                </>
              )}

              {[1, 3, 4].includes(role) && (
                <>
                  <hr />
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link menu-link dropdown-toggle"
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="las la-cog"></i>
                      <span>Paramètres</span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      {[1, 2, 3, 4].includes(role) && (
                        <li>
                          <Link to={"/users"} className="dropdown-item">
                            <i className="lar la-user-circle"></i>
                            <span> Utilisateurs</span>
                          </Link>
                        </li>
                      )}
                      {[1, 3].includes(role) && (
                        <li>
                          <Link to={"/directions"} className="dropdown-item">
                            <i className="fas fa-building"></i>
                            <span> Directions</span>
                          </Link>
                        </li>
                      )}
                      {[4].includes(role) && (
                        <li>
                          <Link
                            to={`/direction/${auth?.direction?.id}`}
                            className="dropdown-item"
                          >
                            <i className="fas fa-building"></i>
                            <span> Direction</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinksTab;
