import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

const Main = ({ children }) => {
  var mybutton = document.getElementById("back-to-top");
  function scrollFunction() {
    100 < document.body.scrollTop || 100 < document.documentElement.scrollTop
      ? (mybutton.style.display = "block")
      : (mybutton.style.display = "none");
  }
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  mybutton &&
    (window.onscroll = function () {
      scrollFunction();
    });

  return (
    <>
      <div id="layout-wrapper">
        <Navbar />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">{children}</div>
          </div>
        </div>
        <Footer />
      </div>
      <button
        onClick={topFunction}
        className="btn btn-danger btn-icon"
        id="back-to-top"
        style={{ display: "block" }}
      >
        <i className="ri-arrow-up-line" />
      </button>
    </>
  );
};

export default Main;
