export const toggleSidebar = () => {
  var baliseHtml = document.documentElement;
  let attr = baliseHtml.getAttribute("data-sidebar-size");
  if (attr === "sm-hover-active") {
    baliseHtml.setAttribute("data-sidebar-size", "sm-hover");
  } else {
    baliseHtml.setAttribute("data-sidebar-size", "sm-hover-active");
  }
};

export const darkMode = () => {
  var baliseHtml = document.documentElement;
  let attr = baliseHtml.getAttribute("data-layout-mode");
  if (attr === "dark") {
    baliseHtml.setAttribute("data-layout-mode", "light");
  } else {
    baliseHtml.setAttribute("data-layout-mode", "dark");
  }
};
