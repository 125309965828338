export const getDirectionDisplayText = (direction) => {
  if (!direction) return "Direction inconnue";

  switch (direction.type) {
    case "Direction Regionale":
      return `${direction?.type} ${
        direction.region?.name ?? "Région inconnue"
      }`;
    case "Direction Provinciale":
      return `${direction?.type} ${
        direction.province?.name ?? "Province inconnue"
      }`;
    case "Service Communal":
      return `${direction?.type} ${
        direction.commune?.name ?? "Commune inconnue"
      }`;
    default:
      return direction?.type ?? "Type inconnu";
  }
};
