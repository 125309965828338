import React from "react";
import "./loading.css";
function LoadingComponent() {
  return (
    <div className="loading-container">
      <>
        <div
          className="spinner-border"
          style={{ width: "1rem", height: "1rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
        <div
          className="spinner-grow"
          style={{ width: "1rem", height: "1rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </>
    </div>
  );
}

export default LoadingComponent;
